// import styled from "styled-components";
import React, { Children } from "react"
import { css } from '@emotion/css'
import tw, { GlobalStyles } from "twin.macro"

// I am getting a property children is missing in type
// https://stackoverflow.com/questions/60840693/property-children-is-missing-in-type
// https://stackoverflow.com/questions/64596282/styled-components-in-a-hoc-react-component
// React.FC defines children and you state manually that you are passing the className

//import '../../styles/typography.css'

// How do I render lite youtube if it does not exist on intrinsic elements?
import { LiteYouTubeEmbedOpt } from "./video/LiteYouTubeEmbedOpt";

const tailwindStyle = css`
    @tailwind base;
    @tailwind components;
    @tailwind utilities;
`;

const youTubeStyle = css`
        div#youtubeframe {
            cursor: pointer;
        }


        iframe {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }

`

const composedStyle = css`
    ${tailwindStyle};
    ${youTubeStyle};
`

  // https://github.com/ibrahimcesar/react-lite-youtube-embed/blob/main/src/lib/index.tsx

interface StyleYTProps {
    className?: string;
    youTubeID: string;
    shorts: boolean;
    title: string;
    children?: React.ReactNode
    width?: number;
    height?: number;
    relativespacing?: number;

}


// export const StyledYouTubeOpt: React.FC<{className?: string, youTubeID: string, title: string}> = ({ className, youTubeID, 
//     title, children }) => {

/**
 * This react component takes in a figure static image as a child
 * That background image (or image with overlay) is displayed if the iframe is not ready
 * 
 * @param param0 
 * @returns 
 */
export const StyledYouTubeOpt: React.FC<StyleYTProps> = ({...props}: StyleYTProps) => {

        const className = props.className;
        const youTubeID = props.youTubeID;
        const title = props.title;
        const children = props.children;
        const shortsBool = props.shorts;
        
        const width = props.width ? props.width : 298;
        const height = props.height ? props.height : 157;
        const relativespacing = props.relativespacing ? props.relativespacing : 4; 
        const childrenArray = Children.toArray(children);



    return(
        <>
        <div className={composedStyle}>
            <GlobalStyles />
            <div id="youtubeframe"
                className={className}
            >


                <LiteYouTubeEmbedOpt
                    
                    id={youTubeID}
                    shorts={shortsBool}
                    title={title}
                    cookie={true}
                    noCookie={false} // This is deprecated?
                    playlist={false} // id is from a single video
                    poster="hqdefault"
                    params="controls=1&modestbranding"

                    webp={true}
                    aspectWidth={props.width}
                    aspectHeight={props.height}

                    width={width} // RAB added to change size of player and thumbnail
                    height={height} // RAB added to change size of player and thumbnail
                    relativespacing={relativespacing}
                >
                   {children}
                </LiteYouTubeEmbedOpt>
            </div>
        </div>
        </>)
}

