
import React, {useState} from 'react'

import { GlobalStyles } from "twin.macro"
import { css } from '@emotion/css'

const tailwindStyle = css`
    @tailwind base;
    @tailwind components;
    @tailwind utilities;
`;

const composedStyle = css`
    ${tailwindStyle};
`;

export function FAQ(props) {
    
    // const breadcrumbLD = props.breadcrumbLD;
    // const itemListElement = breadcrumbLD.itemListElement;
    // console.log("item list element", itemListElement)


    const [isVisible, setIsVisible] = useState(false);

    const idString = "#".concat(props.id);

    return (

        <div className={composedStyle}>
            <GlobalStyles />
                
                <a href={idString} tw=" font-Xpress text-2xl text-elegant-dark-mango ">{props.question}</a>
                {props.children}

        </div>
        
    )
}