
import React from 'react'

import { GlobalStyles } from "twin.macro"
import { css } from '@emotion/css'

const tailwindStyle = css`
    @tailwind base;
    @tailwind components;
    @tailwind utilities;
`;

const composedStyle = css`
    ${tailwindStyle};
`;

 function InfoSectionHeaderSmall(props) {
    
    // const breadcrumbLD = props.breadcrumbLD;
    // const itemListElement = breadcrumbLD.itemListElement;
    // console.log("item list element", itemListElement)


    return (

        <div className={composedStyle}>
            <div id={props.id} tw="flex bg-elegant-bright-green font-Xpress pt-4 pb-8" style={{justifyContent: "space-between"}}>
                <div tw="uppercase text-2xl text-elegant-white ml-2 pl-2 w-20 text-left">{props.product}</div>
                <div tw="text-2xl pl-2 pr-2 my-auto">{props.sectiontitle}</div>
            </div>
            <div tw="flex bg-elegant-bright-green text-elegant-green font-Xpress pb-4">
                {/* put the relative links in here */}
                {/* How do I highlight over the relevant parts */}
                <a href="/" tw="hover:text-elegant-regular-black" > Home </a> 
                <span tw="text-elegant-regular-black px-1"> / </span> 
                <a href="/courses/data-visualization-metaverse/"> Courses </a> 
                <span tw="text-elegant-regular-black px-1"> / </span> 
                <a href="/courses/data-visualization-metaverse/" style={{width: "8.5rem"}}> {props.product} </a>
                <span tw="text-elegant-regular-black px-1"> / </span>
                {props.sectiontitle} 
            </div>
        </div>
        
    )
}

function InfoSectionHeaderLarge(props) {
    
    // const breadcrumbLD = props.breadcrumbLD;
    // const itemListElement = breadcrumbLD.itemListElement;
    // console.log("item list element", itemListElement)


    return (
        <div className={composedStyle}>
            <div className="infolarge" id={props.id} tw="bg-elegant-bright-green w-screen font-Xpress pt-4 pl-4 pb-8 block" >
                <div className="laptopcontainer" style={{justifyContent: "space-between"}} tw="flex max-w-4xl pt-10" >
                    <div tw="uppercase text-2xl text-elegant-white">{props.product}</div>
                    <h2 tw="text-2xl">{props.sectiontitle}</h2>
                </div>
                <div tw="flex text-elegant-green mt-4 pb-4">
                    <a href="/" tw="hover:text-elegant-regular-black" > Home </a> 
                    <span tw="text-elegant-regular-black px-1"> / </span>
                    <a href="/courses/data-visualization-metaverse/" tw="hover:text-elegant-regular-black"> Courses </a>
                    <span tw="text-elegant-regular-black px-1"> / </span> 
                    <a href="/courses/data-visualization-metaverse/" tw="hover:text-elegant-regular-black" > {props.product} </a>
                    <span tw="text-elegant-regular-black px-1"> / </span>
                    {props.sectiontitle} 
                </div>
            </div>
        </div>
        
    )
}



export function InfoSectionHeader(props) {
    

    return (

        <div className={composedStyle}>
            <GlobalStyles />
            <div tw="md:hidden">
                <InfoSectionHeaderSmall
                    id={props.id}
                    product={props.product}
                    sectiontitle={props.sectiontitle}
                />
            </div>
            <div tw="hidden md:flex">
                <InfoSectionHeaderLarge
                    id={props.id}
                    product={props.product}
                    sectiontitle={props.sectiontitle}
                />
            </div>
        </div>
        
    )
}