import * as React from "react";

// Reference: https://github.com/ibrahimcesar/react-lite-youtube-embed/blob/main/src/lib/index.tsx

// Different types of resolutions
type imgResolution =
  | "default"
  | "mqdefault"
  | "hqdefault"
  | "sddefault"
  | "maxresdefault";


interface LiteYouTubeOpt {

    // Option to translate watch to local language
    announce?: string;

    // ID of video or playlist
    id: string;

    // Always provide a title for iframes to help with accessibility.
    title: string;

    // name of the activated class
    activatedClass?: string;

    // Used to determine aspect ratio
    aspectHeight?: number;
    aspectWidth?: number;
    
    // RAB - height of the Youtube Player on the page
    height?: number;

    // option to name iframe class
    iframeClass?: string;
    
    noCookie?: boolean;
    cookie?: boolean;

    // parameters for youtube embed url
    params?: string;

    // option to name youtube player class
    playerClass?: string;
    // ID for the video in the playlist that you want to use for the cover jpg. I am not currently using this. 
    playlistCoverId?: string;
    // Use true when your id be from a playlist
    playlist?: boolean;
    poster?: imgResolution;

    // This is a manual amount of spacing to show the iframe
    relativespacing?: number;

    shorts?: boolean;

    // use webp or jpg for the thumbnail
    webp?: boolean;

    // width of the Youtube player on the page
    width?: number;


    // option to name wrapper class
    wrapperClass?: string;

    thumbnail?: string;

    // This function will be called before the iframe is added.
    // This function returns nothing. 

    onIframeAdded?: () => void;

    // 2022-05-05 Added to pull in background image
    children?: React.ReactNode
  }
  

function addManualPadding(size: number) {
  let manualPadding:any = [];

  for( var i = 0; i < size; i++){
    const row = (
      <>
        <span>&nbsp;&nbsp;</span>
        <h2>&nbsp</h2>
      </>
    );

    manualPadding.push(
      row
    );
  }


  const finalSpaces = (
    <span>&nbsp;&nbsp;</span>
  );


  manualPadding.push(finalSpaces);

  return manualPadding;
}


export const LiteYouTubeEmbedOpt: React.FC<LiteYouTubeOpt> =({...props}: LiteYouTubeOpt) => {
  // const children = props.children;

  // relative spacing for the iframe
  const relativeSpacing = props.relativespacing ? props.relativespacing : 4; 


   // Default values for height and width
   const liteYTWidth = props.height ? props.height : 298;
   const liteYTHeight = props.width ? props.width : 157;

   const [preconnected, setPreconnected] = React.useState(false);
   const [iframe, setIframe] = React.useState(false);

   // TODO Iframe must match the dimensions of the container that is being displayed

    // conversion to utf-8 for use with URI
    const videoId = encodeURIComponent(props.id);
    const videoPlaylisCovertId = typeof props.playlistCoverId === 'string' ? encodeURIComponent(props.playlistCoverId) : null;
    const videoTitle = props.title;

    // What kind of thumbnail is returned from YouTube?
    const posterImp = props.poster || "hqdefault";

    // Parameters for playing the iframe 
    const paramsImp = `&${props.params}` || "";

    // Site is currently in English. This allows you to change the word watch to something else.
    // This is used in the aria label in the button
    const announceWatch = props.announce || "Watch";

    // Determine aspect ratio based on 16:9 ratio
    const aspectHeight = props.aspectHeight || 9;
    const aspectWidth = props.aspectWidth || 16;

    let ytUrl = props.noCookie
    ? "https://www.youtube-nocookie.com"
    : "https://www.youtube.com";

    ytUrl = props.cookie
    ? "https://www.youtube.com"
    : "https://www.youtube-nocookie.com";

    ytUrl = props.shorts 
    ? "https://www.youtube.com/shorts"
    : "https://www.youtube.com"

    const iframeSrc = !props.playlist
    ? `${ytUrl}/embed/${videoId}?autoplay=1${paramsImp}`
    : `${ytUrl}/embed/videoseries?autoplay=1&list=${videoId}${paramsImp}`;

    // class names
    const iframeClassImp = props.iframeClass || "";
    const playerClassImp = props.playerClass || "lty-playbtn";
    const wrapperClassImp = props.wrapperClass || "yt-lite";

    const onIframeAdded = props.onIframeAdded || function() {};

    const addIframe = () => {
      if (iframe) return;
      onIframeAdded()
      setIframe(true);
    };


    // I am placing a manual block for space. This should be fixed
    const manualPadding = addManualPadding(relativeSpacing);




    return (
      <>
      {/* clicking anywhere on the image is going to set the iframe */}
      <div tw="flow" className="liteyoutube">
       {!iframe&&
           <article
             onClick={addIframe}
             className="thumbnailclass"
           >
             {props.children}
           </article>
         }
         {/* problem is the article sits BELOW the information that is displayed */}
         <article
           className={`${wrapperClassImp}`}
           data-title={videoTitle}
         >
             <button
               className={playerClassImp}
               aria-label={`${announceWatch} ${videoTitle}`}>
             </button>
    
           {iframe&&(
             <div className="ifrmwrapper" style={{position: "relative"}}>
               <iframe style={{width: liteYTWidth, height: liteYTHeight}}
                 className={iframeClassImp}
                 title={videoTitle}
                 width={liteYTWidth}
                 height={liteYTHeight}
                 allow="accelerometer; gyroscope; autoplay; encrypted-media"
                 src={iframeSrc}
                 allowFullScreen={true}
               ></iframe>
              {manualPadding}
               
             </div>
           )}
         </article>
       </div>
      </>
    )
}






